exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-old-tsx": () => import("./../../../src/pages/index_old.tsx" /* webpackChunkName: "component---src-pages-index-old-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-inschrijven-sponsoren-tsx": () => import("./../../../src/pages/inschrijven-sponsoren.tsx" /* webpackChunkName: "component---src-pages-inschrijven-sponsoren-tsx" */),
  "component---src-pages-inschrijven-tsx": () => import("./../../../src/pages/inschrijven.tsx" /* webpackChunkName: "component---src-pages-inschrijven-tsx" */),
  "component---src-pages-sponsorpaketten-tsx": () => import("./../../../src/pages/sponsorpaketten.tsx" /* webpackChunkName: "component---src-pages-sponsorpaketten-tsx" */)
}

